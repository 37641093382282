
import { Options, Vue, prop } from "vue-class-component";
import VueApexCharts from "vue3-apexcharts";

class Props {
  seriesData = prop<any[]>({
    default: [],
    type: Array
  });
  categoriesData = prop<any[]>({
    default: [],
    type: Array
  });
  height = prop<number>({
    default: 350,
    type: Number
  });
  mainColor = prop<string>({
    default: "#36B37E",
    type: String
  });
  colorLabel = prop<string>({
    default: "#78787A",
    type: String
  });
  tooltip = prop<boolean>({
    default: false,
    type: Boolean
  });
  isPercentage = prop<boolean>({
    default: true,
    type: Boolean
  });
}

@Options({
  components: {
    VueApexCharts
  }
})
export default class ColumnBar extends Vue.with(Props) {
  series = [
    {
      name: "Performa",
      data: this.seriesData
    }
  ];

  chartOptions = {
    chart: {
      fontFamily: "Poppins",
      height: this.height,
      type: "area",
      toolbar: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 3,
      curve: "smooth"
    },
    annotations: {
      points: this.seriesData.map((item: any, index: number) => {
        return {
          x: this.categoriesData[index],
          y: Number(item) + 1,
          marker: {
            size: 0,
            fillColor: "#fff",
            strokeColor: "#333",
            strokeWidth: 3,
            shape: "circle",
            radius: 2,
            OffsetX: 0,
            OffsetY: 0,
            cssClass: ""
          },
          label: {
            borderWidth: 0,
            borderRadius: 2,
            text: `${item}${this.isPercentage ? "%" : ""}`,
            style: {
              background: "transparent",
              color: "#006644",
              fontSize: "10px",
              fontWeight: 600,
              fontFamily: "Poppins",
              cssClass: "apexcharts-point-annotation-label"
            }
          }
        };
      })
    },
    markers: {
      size: 5,
      colors: [this.mainColor],
      strokeColor: "#E3FCEF",
      strokeWidth: 3,
      strokeOpacity: 0.9
    },
    colors: [this.mainColor],
    xaxis: {
      categories: this.categoriesData,
      labels: {
        rotate: 0,
        style: {
          colors: this.seriesData.map(() => this.colorLabel),
          fontSize: "8px",
          fontFamily: "Poppins",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-label"
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: [this.colorLabel],
          fontSize: "8px",
          fontFamily: "Poppins",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-label"
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value: any) => `${value}${this.isPercentage ? "%" : ""}`
      }
    },
    fill: {
      colors: [this.mainColor],
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    grid: {
      show: true,
      borderColor: "#E0E0E0",
      strokeDashArray: 5,
      position: "back"
    },
    tooltip: {
      enabled: this.tooltip
    }
  };
}
